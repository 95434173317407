<template>
  <div class="DateInput">
    <label :for="id">{{ label }}</label>
    <Datepicker :id="id" :class="{ 'whiteBG': whiteBg }" v-model="dateValue" format="dd-MM-yyyy" locale="es"
      :flow="['year', 'month', 'day']" :year-range="yearRange"></Datepicker>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'DateInput',
  components: {
    Datepicker
  },
  props: {
    label: String,
    modelValue: [String, Date],
    id: String,
    whiteBg: Boolean,
    yearRange: {
      type: Array,
      default: () => [1900, 2100]
    }
  },
  computed: {
    dateValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        this.$emit('date-selected', newValue);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.DateInput {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;
    padding-bottom: 5px;
  }
}
</style>
