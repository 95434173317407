<template>
  <p :class="showErrors  ? 'show-errors' : 'hide-errors'">
    <i class="fas fa-exclamation-triangle"></i>&nbsp;<slot></slot>
  </p>
</template>

<script>
  export default {
    name: 'FormErrors',
    props: [
      'showErrors'
    ]
  }
</script>

<style scoped lang="scss">
  .hide-errors, .show-errors {
    font-size: 12px;
    color: white;
    background: red;
    padding: 5px 0;
    margin: 0;
    cursor: default;
    width: 100%;
    text-align: center;
    transition: 300ms;
  }
  .hide-errors {
    font-size: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
  .show-errors {
    opacity: 1;
  }
</style>