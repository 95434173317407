<template lang="html">
  <div>
    <div
      v-if="isButton"
      class="ProfilePicture ProfilePicture__Button"
      @click="openModal"
      :style="{ backgroundImage: 'url(\'' + picture + '\')' }">
      <div class="ProfilePicture__Button--Placeholder" v-if="!picture">
        <svg viewBox="0 0 150 150">
          <path d="M69 17.7h12v114.7H69z" fill="#d6d6d6" />
          <path d="M17.7 69h114.7v12H17.7z" fill="#d6d6d6" />
        </svg>
        <span>{{$t('photo_profile')}}</span>
      </div>
      <a class="EditButton--Centered" v-if="picture">
        <i class="fas fa-pen"></i>
      </a>
    </div>

    <div class="ProfilePicture" v-if="!isButton">
      <a class="EditButton" @click="openModal">
        <i class="fas fa-pen"></i>
      </a>
      <Avatar :src="picture" />
    </div>

    <photo-uploader
      :resizeWidth="600"
      :show="showModal"
      @close-modal="closeModal"
      @photo-saved="savePhoto"
    ></photo-uploader>
  </div>
</template>

<script>
  import PhotoUploader from '@/components/PhotoUploader.vue';
  import Avatar from '~/components/Avatar';

  export default {
    name: 'ProfilePicture',

    components: {
      PhotoUploader,
      Avatar
    },

    props: {
      picture: {
        type    : String,
        default : ''
      },
      isButton: {
        type    : Boolean,
        default : false
      }
    },

    data ( ) {
      return {
        showModal: false,
      }
    },

    methods: {

      openModal ( ) {
        this.showModal = true;
      },

      closeModal ( ) {
        this.showModal = false;
      },

      savePhoto ( path ) {
        this.closeModal( );
        this.$emit( 'photo-saved', path );
      },

    }
  }
</script>

<style lang="scss">
  .ProfilePicture {
    .Avatar__Background {
      padding-top         : 100% !important;
      position            : absolute;
      top                 : 0;
      left                : 0;
    }
  }
</style>

<style scoped lang="scss">
  .ProfilePicture {
    position              : relative;
    display               : flex;
    justify-content       : center;

    .Avatar {
      display             : block;
      position            : unset;
      width               : 100%;
      height              : 0;
      padding-top         : 100%;
    }
  }
</style>
<style scoped lang="scss">
  .ProfilePicture {
    position              : relative;
    display               : flex;
    justify-content       : center;

    &__Button {
      width               : 100px;
      height              : 100px;
      background          : white;
      color               : #d6d6d6;
      margin              : .5rem;
      padding             : 10px;
      display             : flex;
      flex-direction      : column;
      justify-content     : center;
      align-items         : center;
      text-transform      : uppercase;
      font-size           : .7rem;
      font-weight         : bold;
      border              : 1px solid #e4e4e4;
      cursor              : pointer;
      background-position : center;
      background-size     : cover;

      &--Placeholder {
        display           : flex;
        align-items       : center;
        flex-direction    : column;
        height            : 100%;
      }

      svg {
        width             : 100%;
        height            : 100%
      }

      &--edit {
        top               : 50%;
        right             : 50%;
      }

      &:focus {
        outline           : none;
      }
    }

    .EditButton {
      &, &--Centered {
        position          : absolute;
        bottom            : 1rem;
        right             : 1rem;
        width             : 24px;
        height            : 24px;
        display           : flex;
        align-items       : center;
        justify-content   : center;
        background        : white;
        color             : #db0000;
        cursor            : pointer;
        z-index           : 99;
      }

      &--Centered {
        top               : 50%;
        left              : 50%;
        transform         : translate( -50%, -50% );
      }
    }
  }
</style>
