<template>
  <div class="TextInput" :class="divDynamicClasses">
    <label :for="id" v-text="label"></label>
    <input
      :id="id"
      type="text"
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      v-if="!type"
      @input="$emit( 'typing', $event.target.value, id, $event )"
      :class="inputDynamicClasses"
    />
    <input
      :id="id"
      :type="type"
      v-model="value"
      :disabled="disabled"
      v-if="type === 'date'"
      @input="$emit( 'change', $event.target.value, id, $event )"
      :class="inputDynamicClasses"
    />
    <input
      :id="id"
      :type="type"
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      v-if="type === 'password'"
      @input="$emit( 'typing', $event.target.value, id, $event )"
      :class="inputDynamicClasses"
    />
    <input
      :id="id"
      :type="type"
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      v-if="type === 'number'"
      @input="$emit( 'typing', $event.target.value, id, $event )"
      :class="inputDynamicClasses"/>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    label       : String,
    modelValue  : String,
    id          : String,
    type        : String,
    disabled    : Boolean,
    placeholder : String,
    error       : {
      type: Boolean,
      default: false,
    },
    whiteBg     : {
      type    : Boolean,
      default : false
    },
    isAuthInput : {
      type    : Boolean,
      default : false
    }
  },
  emits: ['update:modelValue','change','typing'],
  computed: {
    divDynamicClasses ( ) {
      return [
        this.isAuthInput && 'p-0'
      ].join( ' ' ).split( ' ' );
    },
    inputDynamicClasses ( ) {
      return [
        this.whiteBg ? 'white-bg' : '',
        this.error ? 'error' : '',
        this.isAuthInput && 'fs-1'
      ].join( ' ' ).split( ' ' );
    },
    value: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .TextInput {
    padding           : .5rem;
    display           : flex;
    flex-direction    : column;
    width             : 50%;

    @media screen and ( max-width: 768px ) {
      width           : 100%;
    }

    label {
      text-transform  : uppercase;
      font-weight     : 600;
      font-size       : .8rem;
      padding-bottom  : 5px;
    }

    input, input[type="date"] {
      background      : #f4f4f4;
      border          : 1px solid #e4e4e4;
      font-size       : 1.2rem;
      padding         : .5rem 1rem;
      width           : 100%;

      &:disabled {
        cursor        : not-allowed;
      }

      &[type="date"] {
        font-family   : inherit;
        font-size     : inherit;
      }

      &:focus {
        outline       : none;
      }
    }
  }

  .white-bg {
    background        : white !important;
  }

  .error {
    border:1px solid #d9534f!important;
  }

  .p-0 {
    padding           : 0 !important;
  }

  .fs-1 {
    font-size         : 1rem !important;
  }
</style>
